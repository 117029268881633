import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NavigatorPage = () => (
  <Layout>
    <SEO 
      title="Mood Navigator - Mood Tracker App - Pluralistic Networks"
      description="Recognize, track, and reflect on your moods with the Mood Navigator web application."
    />

    <div class="maxWidth pagePadding" style={{ marginTop: "78px" }}>
      <div style={{ textAlign: "center" }}>
        <span class="pageSub2">Welcome to</span>
        <span
          class=" pageTitle2"
          style={{ paddingLeft: "13px", color: "#a1bca5" }}
        >
          Mood Navigator
        </span>
      </div>
      <div
        class="bigText"
        style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
      >
        An on-demand guide to help you to recognize, reflect and cultivate
        effective moods in teams.
      </div>

      <div
        style={{
          marginTop: "30px",
          marginBottom: "5px",
        }}
        class="topLine"
      >
        &nbsp;
      </div>
      <div
        style={{
          marginBottom: "56px",
        }}
        class="bottomLine"
      >
        &nbsp;
      </div>

      <div class="bigText" style={{ marginTop: "15px", marginBottom: "10px" }}>
        Effective collaboration, and our well-being, require that we learn to
        recognize our moods and not be trapped in moods that hinder taking care
        of what is important to us. Mood Navigator is an application that acts
        as your on-demand guide for recognizing, reflecting upon, and
        effectively navigating moods in teams.
        <br />
        <br />
      </div>

      <div class="reflectionImg"></div>

      <div
        class="sectionTitle"
        style={{ marginTop: "70px", marginBottom: "", color: "#a1bca5" }}
      >
        Navigating moods
      </div>

      <div class="bigText" style={{ marginTop: "15px", marginBottom: "10px" }}>
        We can’t control our moods, but if we take them seriously, we can learn
        to navigate them. Instead of ignoring our moods, we can learn to listen
        to moods that may be in the background, for ourselves and for others,
        and to reflect upon what they are saying to us.
        <br />
        <br /> Based on data from thousands of conversations with hundreds of
        teams, the Mood Navigator provides a structured guide for navigating the
        moods that frequently show up in teams. Mood Navigator is designed to
        help people identify these moods and take action, ultimately helping us
        to navigate away from negative moods and towards expansive ones instead.
      </div>

      <div class="processImg">&nbsp;</div>

      <div class="sectionTitle" style={{ marginTop: "70px", color: "#a1bca5" }}>
        Why moods matter in teams
      </div>

      <div class="bigText" style={{ marginTop: "15px", marginBottom: "0px" }}>
        When we work with others, we inevitably find ourselves in a variety of
        moods. Trust and Distrust. Ambition and Resignation. Confidence and
        Insecurity. Anxiety and Serenity. Just to name a few.
        <br />
      </div>

      <div class="moodiesImg"></div>

      <div class="bigText" style={{ marginTop: "15px", marginBottom: "18px" }}>
        Some moods predispose us to collaborate and to co-invent with members of
        our teams; others don’t. They may prompt us to disengage instead.
        Working well with others, then, requires that we learn to navigate away
        from moods that limit collaboration and innovation, and instead move
        towards moods that foster those goals.
        <br />
        {/*<br /> Request the white paper:{" "}
        <Link class="gotoLink" to="/contact" style={{ color: "#45574b" }}>
          Navigating Moods in Teams
        </Link>
      <br />*/}
      </div>

      <div class="sectionTitle" style={{ marginTop: "45px", color: "#a1bca5" }}>
        Get started today
      </div>
      <div class="bigText" style={{ marginTop: "15px", marginBottom: "18px" }}>
        The Mood Navigator application is available to participants in the&nbsp;
        <Link class="gotoLink" to="/west" style={{ color: "#45574b" }}>
          Working Effectively in Small Teams
        </Link>
        &nbsp; program.
      </div>
    </div>

    <div style={{ textAlign: "center", marginBottom: "50px" }}>
      <button class="trialButton">
        <Link style={{ color: "#fcfefc" }} to="/contact">
          Contact us
        </Link>
      </button>
    </div>

    <div style={{ marginTop: "5px" }} class="topLine">
      &nbsp;
    </div>
  </Layout>
)

export default NavigatorPage
